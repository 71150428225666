import React, { useState, useEffect } from 'react';
import { Button, Form, List, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './DisruptionsFilter.scss';

const getDateTime = (offset = 0) => {
  const date = new Date();
  date.setDate(date.getDate() + offset);
  const iso = date.toISOString();
  return { date: iso.split('T')[0], time: iso.split('T')[1].substr(0, 5) };
};

const isValid = (filter) => {
  const invalidDates = Object.values(filter)
    .filter((v) => v.date && v.time)
    .map((v) => `${v.date}T${v.time}`)
    .filter((iso) => {
      try {
        return new Date(iso).toString() === 'Invalid Date';
      } catch (error) {
        return true;
      }
    });
  if (invalidDates.length > 0) {
    return false;
  }
  return true;
};

const severity = [
  { key: '1', text: '1', value: '1' },
  { key: '2', text: '2', value: '2' },
  { key: '3', text: '3', value: '3' },
];

export const types = [
  { key: 'HINWEIS', text: 'Hinweis', value: 'HINWEIS', color: '#e41a1c' },
  {
    key: 'BAUARBEITEN',
    text: 'Bauarbeiten',
    value: 'BAUARBEITEN',
    color: '#377eb8',
  },
  {
    key: 'EINSCHRAENKUNG',
    text: 'Einschränkung',
    value: 'EINSCHRAENKUNG',
    color: '#4daf4a',
  },
  {
    key: 'VERSPAETUNG',
    text: 'Verspätung',
    value: 'VERSPAETUNG',
    color: '#984ea3',
  },
  { key: 'BUSERSATZ', text: 'Busersatz', value: 'BUSERSATZ', color: '#ff7f00' },
  { key: 'FAHRPLAN', text: 'Fahrplan', value: 'FAHRPLAN', color: '#ffff33' },
];

const initialFilter = {
  operator: '',
  search: '',
  time: getDateTime(),
  type: [],
  starttime: { date: '', time: '' },
  endtime: { date: '', time: '' },
  pubtime: {},
  severity: '',
  head: '',
  product: '',
};

const timePoint = {
  starttime: { date: '', time: '' },
  endtime: { date: '', time: '' },
  time: getDateTime(),
};

const timeRange = {
  starttime: getDateTime(-1),
  endtime: getDateTime(1),
  time: { date: '', time: '' },
};

const toParams = (filter) =>
  Object.entries(filter)
    .filter(([, value]) => value.length > 0 || (value.date && value.time))
    .map(([field, value]) =>
      Array.isArray(value)
        ? [field, value.map((v, i) => (i > 0 ? `${field}=${v}` : v)).join('&')]
        : [field, value],
    )
    .map(([field, v]) => [field, v.date && v.time ? `${v.date}T${v.time}` : v])
    .map((fieldValue) => fieldValue.join('='))
    .join('&');

const propTypes = {
  onChange: PropTypes.func,
};

const defaultProps = {
  onChange: () => {},
};

function DisruptionsFilter({ onChange }) {
  const [filter, setFilter] = useState(initialFilter);
  const [timeMode, setTimeMode] = useState('point');

  const updateFilter = (name, value) => setFilter({ ...filter, [name]: value });
  const updateDate = (n, date) => updateFilter(n, { ...filter[n], date });
  const updateTime = (n, time) => updateFilter(n, { ...filter[n], time });
  const updateTimeMode = (mode) => {
    setTimeMode(mode);
    setFilter(
      mode === 'point'
        ? { ...filter, ...timePoint }
        : { ...filter, ...timeRange },
    );
  };

  useEffect(() => {
    if (isValid(filter)) {
      onChange(toParams(filter));
    }
  }, [filter, onChange]);

  return (
    <Form>
      <Form.Group className="disruptions-filter-types">
        <Form.Dropdown
          label="Typen"
          multiple
          selection
          options={types.map((t) => ({
            ...t,
            content: (
              <>
                <div style={{ backgroundColor: t.color }} />
                <span>{t.text}</span>
              </>
            ),
          }))}
          onChange={(e, { value }) => updateFilter('type', value)}
        />
        <Modal
          className="disruptions-filter-types"
          trigger={<Form.Button icon="info" />}
          header="Typen"
          content={
            <Modal.Content>
              <List>
                {types.map((t) => (
                  <List.Item key={t.key}>
                    <div style={{ backgroundColor: t.color }} />{' '}
                    <span>{t.text}</span>
                  </List.Item>
                ))}
              </List>
            </Modal.Content>
          }
          actions={['Ok']}
        />
      </Form.Group>
      <Form.Dropdown
        label="Schweregrad"
        clearable
        selection
        options={severity}
        onChange={(e, { value }) => updateFilter('severity', value)}
      />
      <Form.Input
        label="Liniennamen"
        value={filter.product}
        onChange={(e, { value }) => updateFilter('product', value)}
      />
      <Form.Input
        label="Head"
        value={filter.head}
        onChange={(e, { value }) => updateFilter('head', value)}
      />
      <Form.Input
        label="Suche"
        value={filter.search}
        onChange={(e, { value }) => updateFilter('search', value)}
      />
      <Form.Input
        label="Betreiber"
        value={filter.pubtime.operator}
        onChange={(e, { value }) => updateFilter('operator', value)}
      />
      <Form.Group widths="equal">
        <Form.Input
          label="Publikationsdatum"
          type="date"
          value={filter.pubtime.date}
          onChange={(e, { value }) => updateDate('pubtime', value)}
        />
        <Form.Input
          label="Publikationszeit"
          type="time"
          value={filter.pubtime.time}
          onChange={(e, { value }) => updateTime('pubtime', value)}
        />
      </Form.Group>
      <Form.Field>
        <Button.Group>
          <Button disabled>Zeit</Button>
          <Button
            active={timeMode === 'point'}
            onClick={() => updateTimeMode('point')}
          >
            Punkt
          </Button>
          <Button
            active={timeMode === 'range'}
            onClick={() => updateTimeMode('range')}
          >
            Raum
          </Button>
        </Button.Group>
      </Form.Field>
      {timeMode === 'point' && (
        <Form.Group widths="equal">
          <Form.Input
            required
            label="Datum"
            type="date"
            value={filter.time.date}
            onChange={(e, { value }) => updateDate('time', value)}
          />
          <Form.Input
            required
            label="Uhrzeit"
            type="time"
            value={filter.time.time}
            onChange={(e, { value }) => updateTime('time', value)}
          />
        </Form.Group>
      )}
      {timeMode === 'range' && (
        <>
          <Form.Group widths="equal">
            <Form.Input
              required
              label="Start-Datum"
              type="date"
              value={filter.starttime.date}
              onChange={(e, { value }) => updateDate('starttime', value)}
            />
            <Form.Input
              required
              label="Start-Uhrzeit"
              type="time"
              value={filter.starttime.time}
              onChange={(e, { value }) => updateTime('starttime', value)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              label="End-Datum"
              type="date"
              value={filter.endtime.date}
              onChange={(e, { value }) => updateDate('endtime', value)}
            />
            <Form.Input
              required
              label="End-Uhrzeit"
              type="time"
              value={filter.endtime.time}
              onChange={(e, { value }) => updateTime('endtime', value)}
            />
          </Form.Group>
        </>
      )}
    </Form>
  );
}

DisruptionsFilter.propTypes = propTypes;
DisruptionsFilter.defaultProps = defaultProps;

export default DisruptionsFilter;

import React, { useEffect, useState } from 'react';
import OLMap from 'ol/Map';
import PropTypes from 'prop-types';
import Popup from 'react-spatial/components/Popup';
import { List } from 'semantic-ui-react';

import './DisruptionsPopup.scss';

const propTypes = {
  map: PropTypes.instanceOf(OLMap),
};

const defaultProps = {
  map: undefined,
};

function DisruptionsPopup({ map }) {
  const [feature, setFeature] = useState(null);
  const [coordinate, setCoordinate] = useState(null);

  useEffect(() => {
    const mapClickListener = (evt) => {
      if (feature) {
        feature.setProperties({ ...feature.getProperties(), popup: false });
        setFeature(null);
      }
      const [f] = evt.map.getFeaturesAtPixel(evt.pixel);
      if (f) {
        f.setProperties({ ...f.getProperties(), popup: true });
        setFeature(f);
        setCoordinate(evt.map.getEventCoordinate(evt.originalEvent));
      }
    };
    // Add map listener on mount and update
    map.on('singleclick', mapClickListener);
    return () => {
      // Remove map listener on unmount and update (prevent listener stacking)
      map.un('singleclick', mapClickListener);
    };
  }, [map, feature]);

  return (
    feature && (
      <Popup
        map={map}
        title="Störung"
        feature={feature}
        popupCoordinate={coordinate}
        onCloseClick={() => {
          if (feature) {
            feature.setProperties({ ...feature.getProperties(), popup: false });
            setFeature(null);
          }
        }}
      >
        <List>
          {Object.entries(feature.getProperties())
            .filter(([k]) => k !== 'geometry' && k !== 'hover')
            .map(([k, v]) => (
              <List.Item key={k}>
                <List.Header>{k}</List.Header>
                {JSON.stringify(v)}
              </List.Item>
            ))}
        </List>
      </Popup>
    )
  );
}

DisruptionsPopup.propTypes = propTypes;
DisruptionsPopup.defaultProps = defaultProps;

export default DisruptionsPopup;

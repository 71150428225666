import OLMap from 'ol/Map';
import React, { useMemo, useState } from 'react';
import BasicMap from 'react-spatial/components/BasicMap';
import Copyright from 'react-spatial/components/Copyright';
import { MaplibreLayer } from 'mobility-toolbox-js/ol';

import Disruptions from './Disruptions';
import BaselayerToggler from './BaselayerToggler';
import './App.scss';

const { REACT_APP_MAPS_API_KEY } = process.env;
const initGraph = new URLSearchParams(window.location.search).get('graph');

const getLayers = (graph) => {
  return [
    new MaplibreLayer({
      name: 'Baselayer',
      visible: true,
      url:
        graph === 'tralis_schematic_sbm'
          ? `https://maps.geops.io/styles/tralis_munich_schematic/style.json?key=${REACT_APP_MAPS_API_KEY}`
          : `https://maps.geops.io/styles/travic_v2/style.json?key=${REACT_APP_MAPS_API_KEY}`,
      properties: {
        isBaseLayer: true,
      },
    }),
  ];
};

const center = [1149722.7037660484, 6618091.313553318];
const map = new OLMap({ controls: [] });

function App() {
  const [graph, setGraph] = useState(initGraph || 'osm');
  const layers = useMemo(() => getLayers(graph), [graph]);
  return (
    <div className="app">
      <BasicMap map={map} center={center} zoom={6} layers={layers} />
      <BaselayerToggler graph={graph} onChange={(g) => setGraph(g)} />
      <Copyright map={map} />
      <Disruptions map={map} graph={graph} />
    </div>
  );
}

export default App;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { Button, Icon, Label, Loader, Segment } from 'semantic-ui-react';
import OLMap from 'ol/Map';
import PropTypes from 'prop-types';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { asArray } from 'ol/color';
import { getUid } from 'ol/util';
import React, { useEffect, useState } from 'react';

import DisruptionsFilter, { types } from './DisruptionsFilter';
import DisruptionsPopup from './DisruptionsPopup';

import './Disruptions.css';

const { REACT_APP_API_URL: api, REACT_APP_API_KEY: key } = process.env;
const geoJSON = new GeoJSON({ featureProjection: 'EPSG:3857' });
const vectorLayer = new VectorLayer({
  source: new VectorSource(),
  style: (feature) => {
    const { type, hover, popup } = feature.getProperties();
    const color = asArray(types.find((t) => t.key === type).color);
    color[3] = hover || popup ? 1 : 0.5;
    return new Style({
      stroke: new Stroke({ width: 10, color }),
      image: new Circle({
        radius: 10,
        fill: new Fill({ color }),
        stroke: new Stroke({ color: '#FFFFFF', width: 2 }),
      }),
    });
  },
});

const propTypes = {
  graph: PropTypes.string,
  map: PropTypes.instanceOf(OLMap),
};

const defaultProps = {
  graph: undefined,
  map: undefined,
};

function Disruptions({ graph, map }) {
  const [params, setParams] = useState(null);
  const [error, setError] = useState('');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    vectorLayer.setMap(map);
    map.on('pointermove', (evt) => {
      const [h] = map.getFeaturesAtPixel(evt.pixel) || [];
      map.getTarget().style.cursor = h ? 'pointer' : 'default';
      vectorLayer
        .getSource()
        .getFeatures()
        .forEach((f) =>
          f.setProperties({ hover: h && getUid(f) === getUid(h) }),
        );
      vectorLayer.getSource().changed();
    });
  }, [map]);

  useEffect(() => {
    if (params) {
      setLoading(true);
      vectorLayer.getSource().clear();
      fetch(`${api}?key=${key}&graph=${graph}&${params}`)
        .then((response) => response.json())
        .then((json) => {
          setError('');
          try {
            if (json.type === 'FeatureCollection') {
              json.features = json.features.filter((f) => f.geometry);
              const disruptions = geoJSON.readFeatures(json);
              vectorLayer.getSource().addFeatures(disruptions);
              setCount(json.features.length);
            } else if (json.type && json.type[0]) {
              setError(json.type[0]);
            } else if (json.time && json.time[0]) {
              setError(json.time[0]);
            } else if (json.error) {
              setError(json.error);
            }
          } catch (err) {
            setError(err.message);
          }
          setLoading(false);
        })
        // eslint-disable-next-line no-console
        .catch((e) => console.error(e));
    }
  }, [graph, params]);

  return (
    <>
      <DisruptionsPopup map={map} />
      <Segment>
        <DisruptionsFilter onChange={(parameters) => setParams(parameters)} />
        {loading ? (
          <Loader active={loading} inline />
        ) : error ? (
          <Label size="large" color="red">
            {error}
          </Label>
        ) : (
          <Button
            icon
            labelPosition="right"
            primary
            onClick={() => {
              const extent = vectorLayer.getSource().getExtent();
              map.getView().fit(extent, map.getSize());
            }}
          >
            {`${count} Störungen`}
            <Icon name="search" />
          </Button>
        )}
      </Segment>
    </>
  );
}

Disruptions.propTypes = propTypes;
Disruptions.defaultProps = defaultProps;

export default Disruptions;

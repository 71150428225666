import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import './BaselayerToggler.css';

const propTypes = {
  graph: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultProps = {
  graph: undefined,
  onChange: () => {},
};

function BaselayerToggler({ graph, onChange }) {
  return (
    <Button.Group className="baselayer-toggler" color="grey">
      <Button active={graph === 'osm'} onClick={() => onChange('osm')}>
        Topographisch
      </Button>
      <Button
        active={graph === 'tralis_schematic_sbm'}
        onClick={() => onChange('tralis_schematic_sbm')}
      >
        Schematisch München
      </Button>
      {/* <Button active={graph === "sbahns"} onClick={() => onChange("sbahns")}>
        Schematisch Stuttgart
      </Button> */}
    </Button.Group>
  );
}

BaselayerToggler.propTypes = propTypes;
BaselayerToggler.defaultProps = defaultProps;

export default BaselayerToggler;
